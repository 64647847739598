<template>
  <div class="min-height position-relative flex-column justify-content-start align-items-center">
    <img src="@/assets/image/pc/6.jpg" width="100%" alt="" />

    <div class="block3 br-mid flex-column justify-content-start align-items-center">
      <div class="width-100 fw-mid margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">未成年人信息</p>
        </div>
      </div>
      <div class="width-100 margin-b flex-row justify-content-start align-items-center">
        <p class="fs-mid width-10 margin-r">{{ data.childName }}</p>
        <p class="fs-mid width-10" v-if="data.childGender == '1'">男</p>
        <p class="fs-mid width-10" v-if="data.childGender == '2'">女</p>
        <p class="fs-mid width-20">{{ data.childAge }}岁</p>
      </div>
      <div class="divider-h margin-b margin-ts"></div>
      <div class="width-100 fw-mid margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">侵害人信息</p>
        </div>
      </div>
      <div class="width-100 margin-b flex-row justify-content-start align-items-center">
        <p class="fs-mid width-20 darkgrey">{{ data.perpetratorName }}</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.childGender == '1'">男</p>
        <p class="fs-mid width-10 darkgrey" v-if="data.perpetratorGender == '2'">女</p>
        <p class="fs-mid width-70 darkgrey"></p>
      </div>
      <div class="divider-h margin-b margin-ts"></div>
      <div class="width-100 fw-mid margin-bs flex-row justify-content-spaceBetween align-items-center">
        <div class="width-40 title">
          <p class="">事件信息</p>
        </div>
      </div>
      <div class="width-100 flex-row justify-content-spaceBetween align-items-star flex-wrap">
        <p class="width-50 margin-bs"><span class="margin-r darkgrey">发生时间：</span>{{ data.happenedTime }}</p>
        <p class="width-50 margin-bs"><span class="margin-r darkgrey">事发地点：</span>{{ data.reportLocation }}</p>
      </div>
      <p class="width-100 margin-bs"><span class="margin-r darkgrey">事件概况：</span></p>
      <p class="fs-mid margin-b black line-height width-100">{{ data.reportContent }}</p>

      <div class="width-100 flex-row justify-content-spaceBetween align-items-star flex-wrap" v-if="data.reportImg">
        <van-image class="margin-bs" width="31%" height="8rem" fit="contain" v-for="item in imageCut(data.reportImg)" :key="item" :src="item" />
      </div>
      <div class="replay-box" v-if="data.reportStatus == '1'">
        <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
          <div class="width-40 title fw-mid flex-row justify-content-start align-items-center">
            <span class="block"></span>
            <p class="">事件处理回复</p>
          </div>
          <p class="width-60 textAlign-r grey">待回复</p>
        </div>
      </div>
      <div class="replay-box" v-else-if="data.reportStatus == '2'">
        <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
          <div class="width-40 title fw-mid flex-row justify-content-start align-items-center">
            <span class="block"></span>
            <p class="">事件处理回复</p>
          </div>
          <p class="width-60 textAlign-r grey">{{ data.updateTime }}</p>
        </div>
        <p class="fs-mid width-100 darkgrey line-height">{{ data.reportReply }}</p>
      </div>
      <div class="replay-box" v-else>
        <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
          <div class="width-40 title fw-mid flex-row justify-content-start align-items-center">
            <span class="block"></span>
            <p class="">事件处理回复</p>
          </div>
          <p class="width-60 textAlign-r grey">未回复</p>
        </div>
      </div>
    </div>
    <div class="width-100 bg-slightGrey fs-sml darkgrey footer position-absolute textAlign-c">
      版权所有 © 浙江省瑞安市人民检察院 备案号：京ICP备10217144号-1
    </div>
  </div>
</template>
<script>
import { clueReportShow } from '@/api/reportApi';

export default {
  name: 'pcInfoClue',
  data() {
    return {
      data: {}
    };
  },
  created() {
    let id = this.$route.query.id;

    this.initData(id);
  },
  methods: {
    initData(id) {
      clueReportShow(id).then((response) => {
        this.data = response.data;
      });
    },
    imageCut(image) {
      let images = image.split(',');
      return images;
    }
  }
};
</script>

<style lang="less" scoped>
.block3 {
  padding: 2rem 5% 10rem;
  width: 70%;
  min-width: 600px;

  .title {
    line-height: 2rem;
    //background: url("../../assets/image/5.png") left bottom no-repeat;
    //background-size: contain;
    .block {
      width: 6px;
      height: 20px;
      background: #4f9eea;
      margin-right: 10px;
    }
    p {
      color: #4f9eea;
      font-size: 1.2rem;
    }
  }
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}
/deep/.van-cell {
  //background: #fafafa;
  //padding: 6px 16px;
  //border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding: 10px 0;
  display: flex;
  justify-content: start;
  align-items: center;
}
/deep/.van-cell::after {
  border: none;
}
/deep/.van-field__control {
  border: 1px solid #eeeeee;
  padding: 5px 16px;
}
/deep/.van-field__control--custom {
  border: none !important;
}

.footer {
  padding: 3rem;
  bottom: 0;
}
.replay-box {
  width: 100%;
  padding: 20px 3%;
  background: rgb(235, 245, 255);
  margin-top: 3vh;
}
</style>
